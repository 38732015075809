import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

export const exporter = (posts) => {
  const postsForExport = posts;
  
  jsonExport(
    postsForExport,
    {
     
      headers: [
        'id',
        'reference',
        'venueId',
        'serviceId',
        'serviceTime',
        'gender',
        'firstname',
        'lastname',
        'dob',
        'idnumber',
        'phone',
        'email',
        'address',
        'postcode',
        'location',
        'notes',
        'createdOn',
        
      ],
      rowDelimiter:";"
    },
    (err, csv) => {
      downloadCSV(csv, 'Bookings'); // download as 'posts.csv` file
    }
  );
};


