// in src/App.js
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { BookingList, PostCreateServices, PostCreateVenue, PostEditServices, PostEditVenue, ServicesList, VenueList, SampleList, PostEditSample, UserList, PostCreateUser, PostEditUser, ShowBooking  } from './Lists';
import {DataProvider, AuthProvider} from './Providers';


const App = () => (
  <Admin authProvider={AuthProvider} dataProvider={DataProvider}>
    <Resource name="booking" list={BookingList}  show={ShowBooking}/>
    <Resource name="venue" list={VenueList} create={PostCreateVenue} edit={PostEditVenue} />
    <Resource name="service" list={ServicesList} create={PostCreateServices} edit={PostEditServices} />
    <Resource name="sample" list={SampleList}  edit ={PostEditSample}/>
    <Resource name="user" list={UserList}  edit={PostEditUser} create={PostCreateUser}/>
  </Admin>
);

export default App;
