const AuthProvider = {
  login: ({ username, password }) => {
    if (username === 'salman' && password === '12345') {
      localStorage.setItem(
        'auth',
        JSON.stringify({ username: username, password: password })
      );
      return Promise.resolve();
    } else {
      throw new Error({ message: 'error' });
    }
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject({ message: false }),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
};

export default AuthProvider;
