// in src/posts.js
import {
  TextInput,
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  required,
  BooleanInput,
  ReferenceField,
  EditButton,
  ShowButton,
  SimpleFormIterator,
  NullableBooleanInput,
  DateInput,
  DateTimeInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  DeleteWithConfirmButton,
  DateField,
  AutocompleteInput,
  ExportButton,
  BulkExportButton,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import { exporter } from './helper/helper';



const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
});

const useIteratorStyle = makeStyles(() => ({
  line: {
    '&.fade-enter': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 500ms ease-in',
  },
  },
}));

const bookingFilter = [<TextInput source="q" label="Search" alwaysOn />, <ReferenceInput label="Venue" source="venueId" reference="venue">
<SelectInput source="name" />
</ReferenceInput>, <ReferenceInput label="Service" source="serviceId" reference="service">
<SelectInput source="name" />
</ReferenceInput>, <DateInput label="Date From " source="dateFrom" />, <DateInput label="Date To " source="dateTo" />];

const CustomToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteWithConfirmButton undoable={false} />
  </Toolbar>
);

const CustomToolbarSample = props => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
  </Toolbar>
);

const PostBulkActionButtons = props => (
  <Fragment>
      <BulkExportButton {...props} />
      
  </Fragment>
);


export const PostCreateVenue = (props) => (
  <Create {...props}>
    
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput multiline source="address" validate={required()} />
        <TextInput multiline source="additionalAddress" />
        <TextInput source="postcode" validate={required()} />
        <TextInput source="location" validate={required()} />
        <TextInput source="phone" validate={required()} />
        <ArrayInput source="services" >
          <SimpleFormIterator >
            <ReferenceInput
              label="Service"
              source="id"
              reference="service"
              validate={[required()]}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Price" source="price" validate={required()} />
            <TextInput
              label="Booking Intervals Mins"
              source="bookingIntervalMinutes"
              validate={required()}
            />
            <TextInput
              label="Booking Per Interval"
              source="bookingsPerInterval"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="businessHours" defaultValue={[
      {
        "weekDay": 0,
       
        "isOpen": 1,
        "timeslots": [
          {
            "open": "09:00:00",
            "close": "12:00:00"
          },
          {
            "open": "14:30:00",
            "close": "18:00:00"
          }
        ]
      },
      {
        "weekDay": 1,
        
        "isOpen": 1,
        "timeslots": [
          {
            "open": "09:00:00",
            "close": "12:00:00"
          },
          {
            "open": "14:30:00",
            "close": "18:00:00"
          }
        ]
      },
      {
        "weekDay": 2,
       
        "isOpen": 1,
        "timeslots": [
          {
            "open": "09:00:00",
            "close": "12:00:00"
          },
          {
            "open": "14:30:00",
            "close": "18:00:00"
          }
        ]
      },
      {
        "weekDay": 3,
        
        "isOpen": 1,
        "timeslots": [
          {
            "open": "09:00:00",
            "close": "12:00:00"
          },
          {
            "open": "14:30:00",
            "close": "18:00:00"
          }
        ]
      },
      {
        "weekDay": 4,
        
        "isOpen": 1,
        "timeslots": [
          {
            "open": "09:00:00",
            "close": "12:00:00"
          },
          {
            "open": "14:30:00",
            "close": "18:00:00"
          }
        ]
      },
      {
        "weekDay": 5,
        
        "isOpen": 1,
        "timeslots": [
          {
            "open": "12:00:00",
            "close": "14:00:00"
          }
        ]
      },
      {
        "weekDay": 6,
        
        "isOpen": 1,
        "timeslots": [
          {
            "open": "12:00:00",
            "close": "16:00:00"
          }
        ]
      }
    ]
}>
          <SimpleFormIterator >
          <TextInput
              
              source="weekDay"
              label="Day"
            />
            <BooleanInput label="Open" source="isOpen" validate={required()} />
            <ArrayInput label= "Time Slots" source="timeslots" >
            <SimpleFormIterator>
            <TextInput
              label="Open"
              source="open"
              aria-readonly
              validate={required()}
            />
            <TextInput
              label="Close"
              source="close"
              validate={required()}
            />
            </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    
  </Create>
);

export const PostCreateServices = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput source="completionTimeMinutes" validate={required()} />
    </SimpleForm>
  </Create>
);

export const PostCreateUser = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstname" validate={required()} />
      <TextInput source="lastname" validate={required()} />
    </SimpleForm>
  </Create>
);

export const BookingList = (props) => (
  <List  filters={bookingFilter} exporter={exporter}  {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="service" source="serviceId" reference="service">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Venue" source="venueId" reference="venue">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="reference" label ="Reference No" />
      <DateField showTime source="serviceTime" label ="Appointment Time" />
      <TextField source="gender" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <DateField source="dob" />
      <TextField source="idnumber" />
      <TextField source="phone" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const VenueList = (props) => (
  <List  bulkActionButtons={<PostBulkActionButtons/>} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="additionalAddress" />
      <TextField source="postcode" />
      <TextField source="location" />
      <TextField source="phone" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ServicesList = (props) => (
  <List  bulkActionButtons={<PostBulkActionButtons/>} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="completionTimeMinutes" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SampleList = (props) => (
  <List bulkActionButtons={<PostBulkActionButtons/>} {...props}>
    <Datagrid rowClick="edit">
    <TextField  label="Patient First Name" source="firstname" />
    <TextField  label="Patient Last Name" source="lastname" />
    <TextField  label="Reference" source="reference" />
      <DateField showTime source="sampledOn" />
      <ReferenceField label="Employee Name" source="executedBy" reference="user">
        <TextField source="firstname" />
      </ReferenceField>
      <ReferenceField label="Employee Last Name" source="executedBy" reference="user">
        <TextField source="lastname" />
      </ReferenceField>
      <TextField source="result" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserList = (props) => (
  <List  bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PostEditVenue = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={required()} />
        <TextInput multiline source="address" validate={required()} />
        <TextInput multiline source="additionalAddress" />
        <TextInput type="number" source="postcode" validate={required()} />
        <TextInput source="location" validate={required()} />
        <TextInput type="number" source="phone" validate={required()} />
        <BooleanInput source="isActive" validate={required()} />
        <ArrayInput source="services">
          <SimpleFormIterator classes={useIteratorStyle()}>
            <ReferenceInput
              label="Service"
              source="id"
              reference="service"
              validate={[required()]}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput type="number" label="Price" source="price" validate={required()} />
            <TextInput
             type="number" label="Booking Intervals Mins"
              source="bookingIntervalMinutes"
              validate={required()}
            />
            <TextInput
             type="number" label="Booking Per Interval"
              source="bookingsPerInterval"
              validate={required()}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="businessHours">
          <SimpleFormIterator disableAdd disableRemove classes={useIteratorStyle()} >
            <TextInput
              
              source="label"
              disabled
              label="Day"
            />
            <BooleanInput label="Open" source="isOpen" validate={required()} />
            <ArrayInput label= "Time Slots" source="timeslots" >
            <SimpleFormIterator>
            <TextInput
              label="Open"
              type="time"
              source="open"
              aria-readonly
              validate={required()}
            />
            <TextInput
            type="time"
              label="Close"
              source="close"
              validate={required()}
            />
            </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const PostEditServices = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput multiline source="description" validate={required()} />
      <TextInput
        multiline
        source="completionTimeMinutes"
        validate={required()}
      />
      <BooleanInput source="isActive" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const PostEditSample = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbarSample />}>
    <TextField  label="Patient First Name" source="firstname" />
    <TextField  label="Patient Last Name" source="lastname" />
    <TextField  label="Reference" source="reference" />
    <ReferenceInput label="Employee" source="executedBy" reference="user" filterToQuery={searchText => ({ firstname: searchText })}>
    <AutocompleteInput optionText="firstname" />
    </ReferenceInput>
      <SelectInput source="result"  choices={[
    { id: 'PENDING', name: 'PENDING' },
    { id: 'NEGATIVE', name: 'NEGATIVE' },
    { id: 'POSITIVE', name: 'POSITIVE' }
      ]} />
    </SimpleForm>
  </Edit>
);


export const PostEditUser = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
    <TextInput source="firstname" />
    <TextInput source="lastname" />
    </SimpleForm>
  </Edit>
);

export const ShowBooking = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Venue" source="venueId" reference="venue">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="service" source="serviceId" reference="service">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="serviceTime" label="Appointment Time" />
      <TextField source="reference" />
      <TextField source="gender" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <DateField source="dob" />
      <TextField source="idnumber" />
      <TextField source="phone" />
      <TextField source="email" />
      <TextField source="address" />
      <TextField source="postcode" />
      <TextField source="location   " />
      <TextField source="notes" />
      <TextField source="createdOn" />
    </SimpleShowLayout>
  </Show>
);


